// eslint-disable-next-line
'use strict'; // code generated by pbf v3.2.1

// DictionaryInfo ========================================

var DictionaryInfo = exports.DictionaryInfo = {};

DictionaryInfo.read = function (pbf, end) {
    return pbf.readFields(DictionaryInfo._readField, {title: "", license: "", release: 0, numberOfEntries: 0, url: "", infoUrl: ""}, end);
};
DictionaryInfo._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.title = pbf.readString();
    else if (tag === 2) obj.license = pbf.readString();
    else if (tag === 3) obj.release = pbf.readVarint(true);
    else if (tag === 4) obj.numberOfEntries = pbf.readVarint(true);
    else if (tag === 5) obj.url = pbf.readString();
    else if (tag === 6) obj.infoUrl = pbf.readString();
};
DictionaryInfo.write = function (obj, pbf) {
    if (obj.title) pbf.writeStringField(1, obj.title);
    if (obj.license) pbf.writeStringField(2, obj.license);
    if (obj.release) pbf.writeVarintField(3, obj.release);
    if (obj.numberOfEntries) pbf.writeVarintField(4, obj.numberOfEntries);
    if (obj.url) pbf.writeStringField(5, obj.url);
    if (obj.infoUrl) pbf.writeStringField(6, obj.infoUrl);
};

// Entry ========================================

var Entry = exports.Entry = {};

Entry.read = function (pbf, end) {
    return pbf.readFields(Entry._readField, {ts: 0, i: 0, d: "", l: "", s: "", e: ""}, end);
};
Entry._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.ts = pbf.readVarint(true);
    else if (tag === 2) obj.i = pbf.readVarint(true);
    else if (tag === 3) obj.d = pbf.readString();
    else if (tag === 4) obj.l = pbf.readString();
    else if (tag === 5) obj.s = pbf.readString();
    else if (tag === 6) obj.e = pbf.readString();
};
Entry.write = function (obj, pbf) {
    if (obj.ts) pbf.writeVarintField(1, obj.ts);
    if (obj.i) pbf.writeVarintField(2, obj.i);
    if (obj.d) pbf.writeStringField(3, obj.d);
    if (obj.l) pbf.writeStringField(4, obj.l);
    if (obj.s) pbf.writeStringField(5, obj.s);
    if (obj.e) pbf.writeStringField(6, obj.e);
};

// Dictionary ========================================

var Dictionary = exports.Dictionary = {};

Dictionary.read = function (pbf, end) {
    return pbf.readFields(Dictionary._readField, {info: null, entries: []}, end);
};
Dictionary._readField = function (tag, obj, pbf) {
    if (tag === 1) obj.info = DictionaryInfo.read(pbf, pbf.readVarint() + pbf.pos);
    else if (tag === 2) obj.entries.push(Entry.read(pbf, pbf.readVarint() + pbf.pos));
};
Dictionary.write = function (obj, pbf) {
    if (obj.info) pbf.writeMessage(1, DictionaryInfo.write, obj.info);
    if (obj.entries) for (var i = 0; i < obj.entries.length; i++) pbf.writeMessage(2, Entry.write, obj.entries[i]);
};
