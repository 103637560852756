import { Helmet } from "react-helmet";
import dayjs from "dayjs";
import { State } from "../types/dictionary-types";

const About = ({ state } : { state: State }) => (
    <div className="width-limiter">
        <Helmet>
            <link rel="canonical" href="https://dari-dictionary.netlify.app/about" />
            <meta name="description" content="About the Dari Dictionary" />
            <title>About - Dari Dictionary</title>
        </Helmet>
        <h2>About</h2>
        <p>THIS IS A ROUGH PREVIEW IN DEVELOPMENT</p>
        <p>The content of this dictionary is provided by and copyrighted by <a href="https://www.gaplanguages.com/">gaplanguages.com</a>.</p>
        <p>The GAP Languages Dari Dictionary assumes no responsibility or liability for any errors or omissions in
        the content of this site. The information contained in this site is provided on an “as is” basis with
        no guarantees of completeness, accuracy, usefulness or timeliness.</p>
        {state.dictionaryInfo && <p className="text-muted">
            Number of Entries: {state.dictionaryInfo.numberOfEntries} - Updated: {dayjs(state.dictionaryInfo.release).toString()}
        </p>}
    </div>
);

export default About;
