// TODO: Put the DB sync on the localDb object, and then have it cancel()'ed and removed as part of the deinitialization
// sync on initialization and cancel sync on de-initialization

import { Component } from "react";
import { withRouter, Route, RouteComponentProps } from "react-router-dom";
import Helmet from "react-helmet";
import BottomNavItem from "./components/BottomNavItem";
import SearchBar from "./components/SearchBar";
import DictionaryStatusDisplay from "./components/DictionaryStatusDisplay";
import About from "./screens/About";
import Options from "./screens/Options";
import Results from "./screens/Results";
import IsolatedEntry from "./screens/IsolatedEntry";
import { 
    saveOptions,
    readOptions,
} from "./lib/local-storage";
import { dictionary, pageSize } from "./lib/dictionary";
import {
    optionsReducer,
    textOptionsReducer,
} from "./lib/options-reducer";
import hitBottom from "./lib/hitBottom";
import getWordId from "./lib/get-word-id";
import { CronJob } from "cron";
import Mousetrap from "mousetrap";
// import ReactGA from "react-ga";
// tslint:disable-next-line
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import classNames from "classnames";
import { getTextFromShareTarget } from "./lib/share-target";
import { objIsEqual } from "./lib/misc-helpers";
import {
    State,
    TextOptionsAction,
    OptionsAction,
} from "./types/dictionary-types";

// to allow Moustrap key combos even when input fields are in focus
Mousetrap.prototype.stopCallback = function () {
    return false;
}

// const prod = document.location.hostname === "dari-dictionary.netlify.app";

// if (prod) {
//     ReactGA.initialize("UA-196576671-3");
//     ReactGA.set({ anonymizeIp: true });
// }

const possibleLandingPages = [
    "/", "/about", "/settings", "/word", "/new-entries", "/share-target",
];

class App extends Component<RouteComponentProps, State> {
    constructor(props: RouteComponentProps) {
        super(props);
        const savedOptions = readOptions();
        this.state = {
            dictionaryStatus: "loading",
            dictionaryInfo: undefined,
            // TODO: Choose between the saved options and the options in the saved user
            options: savedOptions ? savedOptions : {
              language: "Pashto",
              searchType: "fuzzy",
              theme: /* istanbul ignore next */ (window.matchMedia &&
                window.matchMedia("(prefers-color-scheme: dark)").matches) ? "dark" : "light",
              searchBarPosition: "top",
              textOptions: {
                pTextSize: "normal",
              },
            },
            searchValue: "",
            page: 1,
            isolatedEntry: undefined,
            results: [],
        };
        this.handleOptionsUpdate = this.handleOptionsUpdate.bind(this);
        this.handleTextOptionsUpdate = this.handleTextOptionsUpdate.bind(this);
        this.handleSearchValueChange = this.handleSearchValueChange.bind(this);
        this.handleIsolateEntry = this.handleIsolateEntry.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleDictionaryUpdate = this.handleDictionaryUpdate.bind(this);
    }

    public componentDidMount() {
        window.addEventListener("scroll", this.handleScroll);
        if (!possibleLandingPages.includes(this.props.location.pathname)) {
            this.props.history.replace("/");
        }
        // if (prod) {
        //     ReactGA.pageview(window.location.pathname + window.location.search);
        // }
        dictionary.initialize().then((r) => {
            this.cronJob.start();
            this.setState({
                dictionaryStatus: "ready",
                dictionaryInfo: r.dictionaryInfo,
            });
            if (this.props.location.pathname === "/word") {
                const wordId = getWordId(this.props.location.search);
                if (wordId) {
                    const word = dictionary.findOneByTs(wordId);
                    if (word) {
                        this.setState({ searchValue: word.d });
                    }
                    this.handleIsolateEntry(wordId);
                } else {
                    // TODO: Make a word not found screen
                    console.error("somehow had a word path without a word id param");
                    this.props.history.replace("/");
                }
            }
            if (this.props.location.pathname === "/share-target") {
                const searchString = getTextFromShareTarget(window.location);
                this.props.history.replace("/");
                if (this.state.options.language === "English") {
                    this.handleOptionsUpdate({ type: "toggleLanguage" });
                }
                if (this.state.options.searchType === "alphabetical") {
                    this.handleOptionsUpdate({ type: "toggleSearchType" });
                }
                this.handleSearchValueChange(searchString);
            }
            if (this.props.location.pathname === "/new-entries") {
                this.setState({
                    results: dictionary.getNewWordsThisMonth(),
                    page: 1,
                });
            }
            if (r.response === "loaded from saved") {
                this.handleDictionaryUpdate();
            }
        }).catch((error) => {
            console.error(error);
            this.setState({ dictionaryStatus: "error loading" });
        });
        document.documentElement.setAttribute("data-theme", this.state.options.theme);
        /* istanbul ignore if */
        if (window.matchMedia) {
          const prefersDarkQuery = window.matchMedia("(prefers-color-scheme: dark)");
          prefersDarkQuery.addListener((e) => {
            if (e.matches) {
              this.handleOptionsUpdate({ type: "changeTheme", payload: "dark" });
            }
          });
          const prefersLightQuery = window.matchMedia("(prefers-color-scheme: light)");
          prefersLightQuery.addListener((e) => {
            if (e.matches) {
              this.handleOptionsUpdate({ type: "changeTheme", payload: "light" });
            }
          });
        }
        Mousetrap.bind(["ctrl+down", "ctrl+up", "command+down", "command+up"], (e) => {
            if (e.repeat) return;
            this.handleOptionsUpdate({ type: "toggleLanguage" });
        });
        Mousetrap.bind(["ctrl+b", "command+b"], (e) => {
            if (e.repeat) return;
            this.handleSearchValueChange("");
        });
    }

    public componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
        this.cronJob.stop();
        Mousetrap.unbind(["ctrl+down", "ctrl+up", "command+down", "command+up"]);
        Mousetrap.unbind(["ctrl+b", "command+b"]);
        Mousetrap.unbind(["ctrl+\\", "command+\\"]);
    }

    public componentDidUpdate(prevProps: RouteComponentProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            // if (prod) {
            //     ReactGA.pageview(window.location.pathname + window.location.search);
            // }
            if (this.props.location.pathname === "/") {
                this.handleSearchValueChange("");
            }
            if (this.props.location.pathname === "/new-entries") {
                this.setState({
                    results: dictionary.getNewWordsThisMonth(),
                    page: 1,
                });
            }
        }
        if (getWordId(this.props.location.search) !== getWordId(prevProps.location.search)) {
            // if (prod) {
            //     ReactGA.pageview(window.location.pathname + window.location.search);
            // }
            const wordId = getWordId(this.props.location.search);
            /* istanbul ignore else */
            if (wordId) {
                this.handleIsolateEntry(wordId, true);
            } else {
                this.setState({ isolatedEntry: undefined })
            }
        }
        // if (!["/wordlist", "/settings", "/review-tasks"].includes(this.props.location.pathname)) {
        //     window.scrollTo(0, 0);
        // }
    }


    private handleDictionaryUpdate() {
        dictionary.update(() => {
            this.setState({ dictionaryStatus: "updating" });
        }).then(({ dictionaryInfo }) => {
            if (this.state.dictionaryInfo?.release !== dictionaryInfo?.release) {
                // to avoid unnecessary re-rendering that breaks things
                this.setState({
                    dictionaryStatus: "ready",
                    dictionaryInfo,
                });
            }
        }).catch(() => {
            this.setState({ dictionaryStatus: "error loading" });
        });
    }

    private handleOptionsUpdate(action: OptionsAction) {
        if (action.type === "changeTheme") {
            document.documentElement.setAttribute("data-theme", action.payload);
        }
        // TODO: use a seperate reducer for changing text options (otherwise you could just be updating the saved text options instead of the user text options that the program is going off of)
        const options = optionsReducer(this.state.options, action);
        saveOptions(options);
        if (action.type === "toggleLanguage" || action.type === "toggleSearchType") {
            if (this.props.location.pathname !== "/new-entries") {
                this.setState(prevState => ({
                    options,
                    page: 1,
                    results: dictionary.search({ ...prevState, options }),
                }));
                window.scrollTo(0, 0);
            } else {
                this.setState({ options });
            }
        } else {
            !objIsEqual(this.state.options, options) && this.setState({ options });
        }
    }

    private handleTextOptionsUpdate(action: TextOptionsAction) {
        const textOptions = textOptionsReducer(this.state.options.textOptions, action);
        this.handleOptionsUpdate({ type: "setTextOptions", payload: textOptions });
    }

    private handleSearchValueChange(searchValue: string) {
        if (this.state.dictionaryStatus !== "ready") return;
        if (searchValue === "") {
            this.setState({
                searchValue: "",
                results: [],
                page: 1,
            });
            if (this.props.location.pathname !== "/") {
                this.props.history.replace("/");
            }
            return;
        }
        this.setState(prevState => ({
            searchValue,
            results: dictionary.search({ ...prevState, searchValue }),
            page: 1,
        }));
        if (this.props.history.location.pathname !== "/search") {
            this.props.history.push("/search");
        }
        window.scrollTo(0, 0);
    }

    private handleIsolateEntry(ts: number, onlyState?: boolean) {
        window.scrollTo(0, 0);
        const isolatedEntry = dictionary.findOneByTs(ts);
        if (!isolatedEntry) {
            console.error("couldn't find word to isolate");
            return;
        }
        this.setState({ isolatedEntry });

        if (!onlyState && (this.props.location.pathname !== "/word" || (getWordId(this.props.location.search) !== ts))) {
            this.props.history.push(`/word?id=${isolatedEntry.ts}`);
        }
    }
    
    // TODO: right now not checking user very often cause it messes with the state?
    // causes the verb quizzer to reset?
    private cronJob = new CronJob("* * * * *", () => {
        this.handleDictionaryUpdate();
    })

    /* istanbul ignore next */
    private handleScroll() {
        if (hitBottom() && this.props.location.pathname === "/search" && this.state.results.length >= (pageSize * this.state.page)) {
            const page = this.state.page + 1;
            const moreResults = dictionary.search({ ...this.state, page });
            if (moreResults.length > this.state.results.length) {
                this.setState({
                    page,
                    results: moreResults,
                });
            }
        }
    }

    private handleGoBack() {
        this.props.history.goBack();
        window.scrollTo(0, 0);
    }

    render() {
        return <div style={{
            paddingTop: this.state.options.searchBarPosition === "top" ? "75px" : "7px",
            paddingBottom: "60px",    
        }}>
            <Helmet>
                <title>Dari Dictionary</title>
            </Helmet>
                {this.state.options.searchBarPosition === "top" && <SearchBar
                    state={this.state}
                    optionsDispatch={this.handleOptionsUpdate}
                    handleSearchValueChange={this.handleSearchValueChange}
                />}
                <div className="container-fluid" data-testid="body">
                {this.state.dictionaryStatus !== "ready" ?
                    <DictionaryStatusDisplay status={this.state.dictionaryStatus} />
                :
                    <>
                        <Route path="/" exact>
                            <div className="text-center mt-4">
                                <h4 className="font-weight-light p-3 mb-4">Dari Dictionary</h4>
                                {this.state.options.searchType === "alphabetical" && <div className="mt-4 font-weight-light">
                                    <div className="mb-3"><span className="fa fa-book mr-2" ></span> Alphabetical browsing mode</div>
                                </div>}
                                <p className="font-weight-light p-3 mb-2 mt-4">By <strong><a href="https://www.gaplanguages.com/" className="plain-link">Gap Languages</a></strong></p>
                                <a href="https://www.gaplanguages.com/" className="plain-link font-weight-light">
                                    <div>Dari Lessons</div>
                                </a>
                            </div>
                        </Route>
                        <Route path="/about">
                            <About state={this.state} />
                        </Route>
                        <Route path="/settings">
                            <Options
                                options={this.state.options}
                                optionsDispatch={this.handleOptionsUpdate}
                                textOptionsDispatch={this.handleTextOptionsUpdate}
                            />
                        </Route>
                        <Route path="/search">
                            <Results state={this.state} isolateEntry={this.handleIsolateEntry} />
                        </Route>
                        <Route path="/word">
                            <IsolatedEntry
                                state={this.state}
                                dictionary={dictionary}
                                isolateEntry={this.handleIsolateEntry}
                            />
                        </Route>
                    </>
                }
            </div>
            <footer className={classNames(
                "footer",
                { "bg-white": !["/search", "/word"].includes(this.props.location.pathname) },
                { "footer-thick": this.state.options.searchBarPosition === "bottom" && !["/search", "/word"].includes(this.props.location.pathname) },
                { "wee-less-footer": this.state.options.searchBarPosition === "bottom" && ["/search", "/word"].includes(this.props.location.pathname) },
            )}>
                <Route path="/" exact>
                    <div className="buttons-footer">
                        <BottomNavItem label="About" icon="info-circle" page="/about" />
                        <BottomNavItem label="Settings" icon="cog" page="/settings" />
                    </div>
                </Route>
                <Route path={["/about", "/settings", "/new-entries", "/account", "/wordlist", "/edit", "/review-tasks", "/phrase-builder"]}>
                    <div className="buttons-footer">
                        <BottomNavItem label="Home" icon="home" page="/" />
                    </div>
                </Route>
                {this.state.options.searchBarPosition === "bottom" && <SearchBar
                    state={this.state}
                    optionsDispatch={this.handleOptionsUpdate}
                    handleSearchValueChange={this.handleSearchValueChange}
                    onBottom
                />}
            </footer>
        </div>;
    }
}

export default withRouter(App as any);
