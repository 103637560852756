import {
  Options,
  OptionsAction,
  TextOptionsAction,
  TextOptions,
} from "../types/dictionary-types";

export function optionsReducer(options: Options, action: OptionsAction): Options {
    if (action.type === "toggleLanguage") {
      return {
        ...options,
        language: options.language === "Pashto" ? "English" : "Pashto",
      };
    }
    if (action.type === "toggleSearchType") {
      return {
        ...options,
        searchType: options.searchType === "alphabetical" ? "fuzzy" : "alphabetical",
      }
    }
    if (action.type === "changeTheme") {
      return {
        ...options,
        theme: action.payload,
      };
    }
    if (action.type === "changeSearchBarPosition") {
      return {
        ...options,
        searchBarPosition: action.payload,
      };
    }
    if (action.type === "setTextOptions") {
      return {
        ...options,
        textOptions: action.payload,
      };
    }
    throw new Error("action type not recognized in options reducer");
}

export function textOptionsReducer(textOptions: TextOptions, action: TextOptionsAction): TextOptions {
  if (action.type === "changePTextSize") {
    return {
      ...textOptions,
      pTextSize: action.payload,
    };
  }
  throw new Error("action type not recognized in text options reducer");
}

