import { DictionaryDb } from "./dictionary-core";
import sanitizeDari from "./sanitize-dari";
import fillerWords from "./filler-words";
import { isDariScript } from "./is-dari";
import { fuzzifyPashto } from "./fuzzify-pashto/fuzzify-pashto";
// @ts-ignore
import relevancy from "relevancy";
// import { makeAWeeBitFuzzy } from "./wee-bit-fuzzy";
import * as T from "../types/dictionary-types";

const dictionaryUrl = `https://storage.googleapis.com/gap-dari-dictionary/dictionary`;
const dictionaryInfoUrl = `https://storage.googleapis.com/gap-dari-dictionary/dictionary-info`;

const dictionaryInfoLocalStorageKey = "dariDictionaryInfo";
const dictionaryCollectionName = "dariDictionary";
// const dictionaryDatabaseName = "dictdb.db";
export const pageSize = 35;

const relevancySorter = new relevancy.Sorter();

const db = indexedDB.open('inPrivate');
db.onerror = (e) => {
    console.error(e);
    alert("Your browser does not have IndexedDB enabled. This might be because you are using private mode. Please use regular mode or enable IndexedDB to use this dictionary");
}

const dictDb = new DictionaryDb({
    url: dictionaryUrl,
    infoUrl: dictionaryInfoUrl,
    collectionName: dictionaryCollectionName,
    infoLocalStorageKey: dictionaryInfoLocalStorageKey,
});

function makeSearchStringSafe(searchString: string): string {
  return searchString.replace(/[#-.]|[[-^]|[?|{}]/g, "");
}

function fuzzifyEnglish(input: string): string {
  const safeInput = input.trim().replace(/[#-.]|[[-^]|[?|{}]/g, "");
  // TODO: Could do: cover british/american things like offense / offence
  return safeInput.replace("to ", "")
                  .replace(/our/g, "ou?r")
                  .replace(/or/g, "ou?r");
}

function chunkOutArray<T>(arr: T[], chunkSize: number): T[][] {
  const R: T[][] = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    R.push(arr.slice(i, i + chunkSize));
  }
  return R;
}

function tsOneMonthBack(): number {
    // https://stackoverflow.com/a/24049314/8620945
    const d = new Date();
    const m = d.getMonth();
    d.setMonth(d.getMonth() - 1);
  
    // If still in same month, set date to last day of
    // previous month
    if (d.getMonth() === m) d.setDate(0);
    d.setHours(0, 0, 0);
    d.setMilliseconds(0);
  
    // Get the time value in milliseconds and convert to seconds
    return d.getTime();
}

function alphabeticalLookup({ searchString, page }: {
    searchString: string,
    page: number,
}): T.DictionaryEntry[] {
    const r = new RegExp("^" + sanitizeDari(makeSearchStringSafe(searchString)));
    const regexResults: T.DictionaryEntry[] = dictDb.collection.find({
        $or: [
            {d: { $regex: r }},
            {l: { $regex: r }},
            {s: { $regex: r }},
        ],
    });
    const indexNumbers = regexResults.map((mpd: any) => mpd.i);
    // Find the first matching word occuring first in the Pashto Index
    let firstIndexNumber = null;
    if (indexNumbers.length) {
        firstIndexNumber = Math.min(...indexNumbers);
    }
    // $gt query from that first occurance
    if (firstIndexNumber !== null) {
        return dictDb.collection.chain()
                    .find({ i: { $gt: firstIndexNumber - 1 }})
                    .simplesort("i")
                    .limit(page * pageSize)
                    .data();
    }
    return [];
}

function fuzzyLookup<S extends T.DictionaryEntry>({ searchString, language, page, tpFilter }: {
    searchString: string,
    language: "Pashto" | "English" | "Both",
    page: number,
    tpFilter?: (e: T.DictionaryEntry) => e is S,
}): S[] {
    // TODO: Implement working with both
    return language === "Pashto"
        ? pashtoFuzzyLookup({ searchString, page, tpFilter })
        : englishLookup({ searchString, page, tpFilter })

}

function englishLookup<S extends T.DictionaryEntry>({ searchString, page, tpFilter }: {
    searchString: string,
    page: number,
    tpFilter?: (e: T.DictionaryEntry) => e is S,
}): S[] {
    let resultsGiven: number[] = [];;
    // get exact results
    const exactQuery = { 
        e: {
            $regex: new RegExp(`^${fuzzifyEnglish(searchString)}$`, "i"),
        },
    };
    const exactResultsLimit = pageSize < 10 ? Math.floor(pageSize / 2) : 10;
    const exactResults = dictDb.collection.chain()
                          .find(exactQuery)
                          .limit(exactResultsLimit)
                          .simplesort("i")
                          .data();
    resultsGiven = exactResults.map((mpd: any) => mpd.$loki);
    // get results with full word match at beginning of string
    const startingQuery = { 
        e: {
            $regex: new RegExp(`^${fuzzifyEnglish(searchString)}\\b`, "i"),
        },
        $loki: { $nin: resultsGiven },
    };
    const startingResultsLimit = (pageSize * page) - resultsGiven.length;
    const startingResults = dictDb.collection.chain()
                          .find(startingQuery)
                          .limit(startingResultsLimit)
                          .simplesort("i")
                          .data();
    resultsGiven = [...resultsGiven, ...startingResults.map((mpd: any) => mpd.$loki)];
    // get results with full word match anywhere
    const fullWordQuery = {
        e: {
            $regex: new RegExp(`\\b${fuzzifyEnglish(searchString)}\\b`, "i"),
        },
        $loki: { $nin: resultsGiven },
    };
    const fullWordResultsLimit = (pageSize * page) - resultsGiven.length;
    const fullWordResults = dictDb.collection.chain()
                          .find(fullWordQuery)
                          .limit(fullWordResultsLimit)
                          .simplesort("i")
                          .data();
    resultsGiven = [...resultsGiven, ...fullWordResults.map((mpd: any) => mpd.$loki)]
    // get results with partial match anywhere
    const partialMatchQuery = {
        e: {
            $regex: new RegExp(`${fuzzifyEnglish(searchString)}`, "i"),
        },
        $loki: { $nin: resultsGiven },
    };
    const partialMatchLimit = (pageSize * page) - resultsGiven.length;
    const partialMatchResults = dictDb.collection.chain()
    .where(tpFilter ? tpFilter : () => true)
        .find(partialMatchQuery)
        .limit(partialMatchLimit)
        .simplesort("i")
        .data();
    const results = [
        ...exactResults,
        ...startingResults,
        ...fullWordResults,
        ...partialMatchResults,
    ];
    if (tpFilter) {
        return results.filter(tpFilter);
    }
    return results;
}

function pashtoExactLookup(searchString: string): T.DictionaryEntry[] {
    const index = isDariScript(searchString) ? "d" : "l";
    return dictDb.collection.find({
        [index]: searchString,
    });
}

function pashtoFuzzyLookup<S extends T.DictionaryEntry>({ searchString, page, tpFilter }: {
    searchString: string,
    page: number,
    tpFilter?: (e: T.DictionaryEntry) => e is S,
}): S[] {
    let resultsGiven: number[] = [];
    // Check if it's in Pashto or Latin script
    const search = sanitizeDari(makeSearchStringSafe(searchString));
    const index = isDariScript(search) ? "d" : "l";
    // Get exact matches
    const exactExpression = new RegExp("^" + search);
    const pashtoExactResultFields = [
        {
            [index]: { $regex: exactExpression },
        },
    ];
    const exactQuery = { $or: [...pashtoExactResultFields] };
    // just special incase using really small limits
    // multiple times scrolling / chunking / sorting might get a bit messed up if using a limit of less than 10
    const exactResultsLimit = pageSize < 10 ? Math.floor(pageSize / 2) : 10;
    const exactResults = dictDb.collection.chain()
                          .find(exactQuery)
                          .limit(exactResultsLimit)
                          .simplesort("i")
                          .data();
    resultsGiven = exactResults.map((mpd: any) => mpd.$loki);
  
    // Get fuzzy matches
    const pashtoRegExLogic = fuzzifyPashto(search, {
        script: index === "d" ? "Pashto" : "Latin",
        simplifiedLatin: index === "l",
        allowSpacesInWords: true,
        matchStart: "word",
    });
    const fuzzyPashtoExperssion = new RegExp(pashtoRegExLogic);
    const pashtoFuzzyQuery = [
        {
            [index]: { $regex: fuzzyPashtoExperssion },
        },
    ];
    // fuzzy results should be allowed to take up the rest of the limit (not used up by exact results)
    const fuzzyResultsLimit = (pageSize * page) - resultsGiven.length;
    // don't get these fuzzy results if searching in only English
    const fuzzyQuery = { 
        $or: pashtoFuzzyQuery,
        $loki: { $nin: resultsGiven },
    };
    const fuzzyResults = dictDb.collection.chain()
                    .find(fuzzyQuery)
                    .limit(fuzzyResultsLimit)
                    .data();
    const results = tpFilter
        ? [...exactResults, ...fuzzyResults].filter(tpFilter)
        : [...exactResults, ...fuzzyResults];
    const chunksToSort = chunkOutArray(results, pageSize);
    // sort out each chunk (based on limit used multiple times by infinite scroll)
    // so that when infinite scrolling, it doesn't resort the previous chunks given
    // TODO: If on the first page, only sort the fuzzyResults
    return chunksToSort
        .reduce((acc, cur, i) => ((i === 0)
            ? [
                ...sortByRelevancy(cur.slice(0, exactResults.length), search, index),
                ...sortByRelevancy(cur.slice(exactResults.length), search, index),
            ]
            : [
                ...acc,
                ...sortByRelevancy(cur, search, index),
            ]), []);
}

function sortByRelevancy<T>(arr: T[], searchI: string, index: string): T[] {
    return relevancySorter.sort(arr, searchI, (obj: any, calc: any) => calc(obj[index]));
}

function relatedWordsLookup(word: T.DictionaryEntry): T.DictionaryEntry[] {
    const wordArray = word.e.trim()
        .replace(/\?/g, "")
        .replace(/( |,|\.|!|;|\(|\))/g, " ")
        .split(/ +/)
        .filter((w: string) => !fillerWords.includes(w));
    let results: T.DictionaryEntry[] = [];
    wordArray.forEach((w: string) => {
        let r: RegExp;
        try {
            r = new RegExp(`\\b${w}\\b`, "i");
            const relatedToWord = dictDb.collection.chain()
                                .find({
                                    // don't include the original word
                                    ts: { $ne: word.ts },
                                    e: { $regex: r },
                                })
                                .limit(5)
                                .data();
            results = [...results, ...relatedToWord];
            // In case there's some weird regex fail
        } catch (error) {
            /* istanbul ignore next */
            console.error(error);
        }
    });
    // Remove duplicate items - https://stackoverflow.com/questions/40811451/remove-duplicates-from-a-array-of-objects
    results = results.filter(function(a) {
        // @ts-ignore
        return !this[a.$loki] && (this[a.$loki] = true);
    }, Object.create(null));
    return(results);
}

export function allEntries() {
    return dictDb.collection.find();
}

export const dictionary: T.DictionaryAPI = {
    // NOTE: For some reason that I do not understand you have to pass the functions from the
    // dictionary core class in like this... ie. initialize: dictDb.initialize will mess up the this usage
    // in the dictionary core class
    initialize: async () => await dictDb.initialize(),
    update: async (notifyUpdateComing: () => void) => await dictDb.updateDictionary(notifyUpdateComing),
    search: function(state: T.State): T.DictionaryEntry[] {
        const searchString = state.searchValue;
        if (state.searchValue === "") {
            return [];
        }
        return (state.options.searchType === "alphabetical" && state.options.language === "Pashto")
            ? alphabeticalLookup({
                searchString,
                page: state.page
            })
            : fuzzyLookup({
                searchString,
                language: state.options.language,
                page: state.page,
            });
    },
    exactPashtoSearch: pashtoExactLookup,
    getNewWordsThisMonth: function(): T.DictionaryEntry[] {
        return dictDb.collection.chain()
          .find({ ts: { $gt: tsOneMonthBack() }})
          .simplesort("ts")
          .data()
          .reverse();
    },
    findOneByTs: (ts: number) => dictDb.findOneByTs(ts),
    findRelatedEntries: function(entry: T.DictionaryEntry): T.DictionaryEntry[] {
        return relatedWordsLookup(entry);
    },
}
