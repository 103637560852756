 
import classNames from "classnames";
import * as T from "../types/dictionary-types";

function Entry({ entry, textOptions, nonClickable, isolateEntry }: {
    entry: T.DictionaryEntry,
    textOptions: T.TextOptions,
    nonClickable?: boolean,
    isolateEntry?: (ts: number) => void,
}) {
    return (
        <div
            className={classNames("entry", { clickable: !nonClickable })}
            onClick={(!nonClickable && isolateEntry) ? () => isolateEntry(entry.ts) : undefined}
            data-testid="entry"
        >
                <div>
                    <strong>
                        {entry.d ? `${entry.d} - ` : ""}{entry.l}
                    </strong> 
                    {` `}
                    <em>{entry.s}</em>
                </div>
                <div className="entry-definition">{entry.e}</div>
        </div>
    );
};

export default Entry;
