 
import ButtonSelect from "../components/ButtonSelect";
import { Helmet } from "react-helmet";
import {
    Options,
    PTextSize,
    SearchBarPosition,
    Theme,
    OptionsAction,
    TextOptionsAction,
} from "../types/dictionary-types";

const fontSizeOptions: {
    label: string,
    value: PTextSize,
}[] = [
    { 
        label: "Normal", value: "normal",
    },
    { 
        label: "Larger", value: "larger",
    },
    { 
        label: "Largest", value: "largest",
    }
];

const themeOptions: {
    label: any,
    value: Theme,
}[] = [
    {
        label: <><i className="fa fa-sun"></i> Light</>,
        value: "light",
    },
    {
        label: <><i className="fa fa-moon"></i> Dark</>,
        value: "dark",
    },
];


const searchBarPositionOptions: {
    label: string,
    value: SearchBarPosition,
}[] = [
    {
        label: "Top",
        value: "top",
    },
    {
        label: "Bottom",
        value: "bottom",
    },
];

function OptionsScreen({
    options,
    optionsDispatch,
    textOptionsDispatch,
}: {
    options: Options,
    optionsDispatch: (action: OptionsAction) => void,
    textOptionsDispatch: (action: TextOptionsAction) => void,
}) {
    return <div style={{ maxWidth: "700px", marginBottom: "150px" }}>
        <Helmet>
            <link rel="canonical" href="https://dari-dictionary.netlify.app/settings" />
            <meta name="description" content="Settings for the Dari Dictionary" />
            <title>Settings - Dari Dictionary</title>
        </Helmet>
        <h2 className="mb-3">Settings</h2>
        <h4 className="mb-3">Keyboard Shortucts</h4>
        <table className="table">
            <tbody>
                <tr>
                    <td><kbd>ctrl / ⌘</kbd> + <kbd>up</kbd> / <kbd>down</kbd></td>
                    <td>switch language</td>
                </tr>
                <tr>
                    <td><kbd>ctrl / ⌘</kbd> + <kbd>b</kbd></td>
                    <td>clear search</td>
                </tr>
            </tbody>
        </table>
        <h4 className="mt-3">Theme</h4>
        <ButtonSelect
            small
            options={themeOptions}
            value={options.theme}
            handleChange={(p) => optionsDispatch({ type: "changeTheme", payload: p as Theme })}
        />
        <h4 className="mt-3">Search Bar Position</h4>
        <ButtonSelect
            small
            options={searchBarPositionOptions}
            value={options.searchBarPosition}
            handleChange={(p) => optionsDispatch({ type: "changeSearchBarPosition", payload: p as SearchBarPosition })}
        />
        <div className="small mt-2">Bottom position doesn't work well with iPhones.</div>
        <h4 className="mt-3">Dari Font Size</h4>
        <ButtonSelect
            small
            options={fontSizeOptions}
            value={options.textOptions.pTextSize}
            handleChange={(p) => textOptionsDispatch({ type: "changePTextSize", payload: p as PTextSize })}
        />
    </div>
}

export default OptionsScreen;