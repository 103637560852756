import { Link } from "react-router-dom";
import Entry from "../components/Entry";
import Results from "../screens/Results";
import { Helmet } from "react-helmet";
import {
    State,
    DictionaryAPI,
    DictionaryEntry,
} from "../types/dictionary-types";
import { useState } from "react";

function IsolatedEntry({ state, dictionary, isolateEntry }: {
    state: State,
    dictionary: DictionaryAPI,
    isolateEntry: (ts: number) => void,
}) {
    const textOptions = state.options.textOptions;
    const entry = state.isolatedEntry;
    const [exploded, setExploded] = useState<boolean>(false);
    if (!entry) {
        return <div className="text-center">
            <h4 className="mb-4 mt-4">Word not found</h4>
            <h5><Link to="/">Home</Link></h5>
        </div>;
    }
    const relatedEntries = dictionary.findRelatedEntries(entry);
    return <div className="width-limiter">
        <Helmet>
            <title>{entry.d} - Dari Dictionary</title>
        </Helmet>
        <div className="row">
            <div className="col-10">
                <Entry
                    nonClickable
                    entry={exploded ? explodeEntry(entry) : entry}
                    textOptions={textOptions}
                    isolateEntry={isolateEntry}
                />
            </div>
            <div className="col-2">
                <div className="d-flex flex-row justify-content-end">
                    <div
                        className="clickable mr-3"
                        onClick={() => setExploded(os => !os)}
                    >
                        <i className={`fas fa-${exploded ? "compress" : "expand"}-alt`} />
                    </div>
                </div>
            </div>
        </div>
        {relatedEntries && <>
            {relatedEntries.length ? 
                <>
                    <h4>Related Words</h4>
                    <Results
                        state={{ ...state, results: relatedEntries }}
                        isolateEntry={isolateEntry}
                    />
                </>
            :
                <h5>No related entries</h5>
            }
        </>}
    </div>;
}

function explodeEntry(entry: DictionaryEntry): DictionaryEntry {
    return {
        ...entry,
        d: entry.d.split("").join(" "),
    };
}

export default IsolatedEntry;