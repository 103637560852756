import Entry from "../components/Entry";
import { Helmet } from "react-helmet";
import {
    State,
} from "../types/dictionary-types";

function Results({ state, isolateEntry }: {
    state: State,
    isolateEntry: (ts: number) => void,
}) {
    const textOptions = state.options.textOptions;
    function toDari(l: "Pashto" | "English") {
        return l === "Pashto" ? "Dari" : l;
    }
    return <div className="width-limiter">
        <Helmet>
            <title>Dari Dictionary</title>
        </Helmet>
        {state.results.map((entry) => (
            <Entry
                key={entry.i}
                entry={entry}
                textOptions={textOptions}
                isolateEntry={isolateEntry}
            />
        ))}
        {((state.searchValue && (!state.results.length))) && <div>
            <h5 className="mt-2">No Results Found in {toDari(state.options.language)}</h5>
            {state.options.searchType === "alphabetical" && <div className="mt-4 font-weight-light">
                <div className="mb-3">You are using alphabetical browsing mode</div>
            </div>}
        </div>}
    </div>;
}

export default Results;