import { Options } from "../types/dictionary-types";

export const optionsLocalStorageName = "options5";

export function saveOptions(options: Options): void {
  localStorage.setItem(optionsLocalStorageName, JSON.stringify(options));
};

export const readOptions = (): undefined | Options => {
  const optionsRaw = localStorage.getItem(optionsLocalStorageName);
  if (!optionsRaw) {
    return undefined;
  }
  try {
    const options = JSON.parse(optionsRaw) as Options;
    return options;
  } catch (e) {
    console.error("error parsing saved state JSON", e);
    return undefined;
  }
};
